import {styled, useTheme} from '@mui/material/styles';
import React, { MouseEvent } from 'react';
import { DefaultTheme } from 'styles/Theme/types';

const ScrollToTop = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '48px',
  height: '48px',
  color: '#fff',
  background: `${theme.palette.primary.main} url(/images/icons/arrow-up.png) 50% no-repeat`,
  fontSize: '20px',
  lineHeight: '48px',
  textAlign: 'center',
  zIndex: '100',
  cursor: 'pointer',
  display: 'block',
}));

export const ScrollButton = () => {
  const theme = useTheme<DefaultTheme>();

  const handleClick = (_: MouseEvent) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return(
    <ScrollToTop theme={theme} onClick={handleClick}></ScrollToTop>
  );
};
