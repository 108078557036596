import { Box, SxProps } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { Container } from '@mui/system';
import Link from 'components/Link';
import { useEvent, useStore } from 'effector-react';
import { useLang } from 'locales';
import { $menuStatus, switchMenu } from 'models/app';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import { HeaderLogo } from './HeaderLogo';
import CloseIcon from './icons/Close.svg';
import MenuIcon from './icons/MenuIcon.svg';
import { LangSwitcher } from './LangSwitcher';
import { SocialNetworks } from './SocialNetworks';



const Wrapper = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  position: 'relative',
  width: '100%',
  left: 0,
  top: 0,
  zIndex: 999,
  background: `${theme.colors.chambray} url("/images/header_bg_desktop.png") center no-repeat`,
  backgroundSize: 'cover',
  transition: 'all 300ms ease',
  WebkitTransition: 'all 300ms ease',
  WebkitBoxShadow: '0px 1px 21px 0px rgba(0,0,0,0.1)',
  MozBoxShadow: '0px 1px 21px 0px rgba(0,0,0,0.1)',
  boxShadow: '0px 1px 21px 0px rgba(0,0,0,0.1)',

  // @media max-width: 900px  # TODO - change to 767px
  [theme.breakpoints.down('md')]: {
    background: '#ffffff',
    boxShadow: '0px 0px 10px rgba(93, 62, 129, 0.15)',
  },
}));

const LinksWrapper = styled('div')(() => ({
  marginLeft: 15,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
}));

const Links = styled('div')<{ theme: DefaultTheme }>(({theme})=> ({
  display: 'flex',
  gap: 30,
  '& a': {
    fontSize: '20px',
    fontWeight: 400,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  }
}));

const IconClose = styled(CloseIcon)<{theme: DefaultTheme}>(({theme}) => ({
  position: 'absolute',
  '& path': {
    stroke: theme.palette.primary.main,
    strokeWidth: 3,
    strokeLinecap: 'round',
  },
  top: 21,
  left: 16,
}));

const IconMenu = styled(MenuIcon)<{theme: DefaultTheme}>(({theme}) => ({
  position: 'absolute',
  '& path': {
    stroke: theme.palette.primary.main,
    strokeWidth: 3,
    strokeLinecap: 'round',
  },
  top: 21,
  left: 16,
}));

interface MainHeaderProps {
  hideSocial?: boolean,
  url: string,
}

const MainHeader = ({
  hideSocial=false,
  url
}: MainHeaderProps) => {
  const theme = useTheme<DefaultTheme>();
  const ctx = useLang();
  const {NEXT_PUBLIC_FORM_URL} = process.env;

  const switchMenuEv = useEvent(switchMenu);
  const isOpenMenu = useStore($menuStatus);

  const Icon = ({sx}: {sx: SxProps<DefaultTheme>}) => {
    return (
      isOpenMenu ?
        <IconClose theme={theme} sx={sx} onClick={switchMenuEv} /> :
        <IconMenu theme={theme} sx={sx} onClick={switchMenuEv} />
    );
  };

  return (
    <Wrapper theme={theme} sx={{
      height: {
        xs: 'initial',
        sm: 'initial',
        md: '320px',
      },
      backgroundColor: 'white'
    }}>
      <Container
        maxWidth={'lg'}
        disableGutters
        sx={{
          position: 'relative',
          height: {
            xs: 'initial',
            sm: 'initial',
            md: '100%',
          },
        }}
      >
        {!isOpenMenu ?
          <LinksWrapper>
            <Links theme={theme}>
              <Link url={NEXT_PUBLIC_FORM_URL || ''} simple target={'_blank'} rel="noreferer" title={ctx.link.registerVolunteer} />
              <Link url="" title={ctx.link.schedule} />
              <Link url="/about-project" title={ctx.link.about} />
            </Links>
            <LangSwitcher url={url}/>
          </LinksWrapper>
          : null}
        <HeaderLogo />
        <Icon sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
          }
        }}  />
        <Box sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
          }
        }} >
          {!hideSocial ? <SocialNetworks /> : null}
        </Box>
      </Container>
    </Wrapper>
  );
};

export default MainHeader;
