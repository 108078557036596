import { useStore } from 'effector-react';
import { $menuStatus } from 'models/app';
import React, { ReactNode } from 'react';

interface MenuProps {
  isOpen: Boolean,
  openComponent: ReactNode,
  closedComponent: ReactNode,
}

export const Menu = ({
  openComponent,
  closedComponent,
}: MenuProps) => {
  const menuStatus = useStore($menuStatus);

  return (
    <div>
      <div style={{display: menuStatus ? 'none' : 'block'}}>
        {closedComponent}
      </div>
      <div style={{display: menuStatus ? 'block' : 'none'}}>
        {openComponent}
      </div>
    </div>
  );
};
