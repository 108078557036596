import { SxProps } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import ArrowIcon from './arrow.svg';

const Container = styled('div')({
  display: 'flex',
  cursor: 'pointer',
});

const Value = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& span': {
    marginRight: 8,
    marginTop: 4,
  },
  '&.active': {
    '& svg': {
      transform: 'rotate(-180deg)',
    }
  }
});

const Options = styled('ul')({
  display: 'none',
  opacity: 0,
  position: 'absolute',
  top: 40,
  left: -8,
  width: '55px',
  marginTop: 4,
  listStyleType: 'none',
  backgroundColor: '#fff',
  borderRadius: 4,
  boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
  '& li': {
    cursor: 'pointer',
    padding: '4px 8px',
    overflow: 'hidden'
  },
  '&.active': {
    display: 'block',
    opacity: 1,
  }
});

interface SelectProps {
  value: string | undefined,
  onChange: (value: string) => void,
  options: string[],
  className?: string,
  sx?: SxProps<Theme> | undefined,
}

function useOuterClick(ref: React.MutableRefObject<any>, onClick: (e: MouseEvent) => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick(event);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
}

const Select = ({
  value,
  onChange,
  options,
  className,
  sx,
}: SelectProps) => {
  const [active, setActive] = useState(false);
  const theme: DefaultTheme = useTheme();
  const wrapperRef = useRef(null);
  const handleOuterClick = (e: MouseEvent) => {
    setActive(false);
  };
  useOuterClick(wrapperRef, handleOuterClick);

  return (
    <Container ref={wrapperRef} className={className} sx={sx} onClick={() => setActive(!active)}>
      <Value className={active ? 'active' : ''}>
        <span>{value}</span>
        <ArrowIcon/>
      </Value>
      <Options className={active ? 'active' : ''} sx={{'& li:hover': {backgroundColor: `${theme.palette.primary.main}1a`}}}>
        {options.map(
          (lang, index) => <li onClick={(_) => lang !== value ? onChange(lang) : ''} key={index}>{lang}</li>
        )}
      </Options>
    </Container>
  );
};

export default Select;
