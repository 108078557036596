import { Box, SvgIcon } from '@mui/material';
import {styled,useTheme} from '@mui/material/styles';
import { useWindowSize } from 'components/utils';
import { useStore } from 'effector-react';
import {$logo} from 'models/staticData';
import { MediaAttributes } from 'models/staticData/types';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';


const LogoWrapper = styled('div')<{theme: DefaultTheme}>(({theme}) => ({
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '20px',

  [theme.breakpoints.down('md')]: {
    height: '66px',
    padding: '8px 0',
    overflow: 'hidden',
  },

  '& svg path': {
    fill: theme.palette.primary.main,
  },
}));

const getLogo = (width: number, logo: MediaAttributes) => {
  const isMobile = width <= 900;
  const isTablet = 901 <= width && width <= 1120;
  if (isMobile) {
    return logo?.formats?.small.url;
  } else if(isTablet) {
    return logo?.formats?.medium.url;
  } else {
    return logo.url;
  }
};

export const HeaderLogo = () => {
  const logo = useStore($logo);
  const [width, _] = useWindowSize();
  const IMG_SRC = getLogo(width, logo);

  const theme = useTheme<DefaultTheme>();

  return (
    <LogoWrapper theme={theme}>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
          },
        }}
      >
        <img
          src={IMG_SRC}
          alt="Logo"
          width='100%'
          height='120px'
        />
      </Box>
      <SvgIcon
        viewBox='0 0 50 50'
        sx={{
          height: '50px',
          width: '50px',
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
          }
        }}
      >
        <path d="M0 22.5V23.3056V25.9444V27.5H5V25.9444V23.3056V22.5H0Z" fill="#5D3E81" opacity="0.6"></path>
        <path d="M26.6667 0H24H22.5V5H24H26.6667H27.5V0H26.6667Z" fill="#5D3E81" opacity="0.6"></path>
        <path d="M40.833 21.6664H28.333V9.11084C34.7219 10.4164 39.5552 15.2775 40.833 21.6664Z" fill="#5D3E81" opacity="0.6"></path>
        <path d="M26.6667 45H24H22.5V50H24H26.6667H27.5V45H26.6667Z" fill="#5D3E81" opacity="0.6"></path>
        <path d="M45 22.4999V22.4443V25.0555V27.4999H50V25.0555V22.4443V22.4999H45Z" fill="#5D3E81" opacity="0.6"></path>
        <path d="M22.4999 8.94434V21.9166C22.4999 22.5277 21.9999 23.0277 21.3888 23.0277H8.94434C8.99989 22.4721 9.08322 21.6388 9.16655 21.6388H20.8332V9.19433C21.6666 9.08322 22.2221 8.99989 22.4999 8.94434Z" fill="#5D3E81"></path>
        <path d="M22.5004 28.5833V40.9999C22.2227 40.9444 21.6671 40.8611 20.8338 40.75V29.1388H9.22266C9.11155 28.3055 9.02821 27.7499 8.97266 27.4722H21.3893C22.0004 27.4722 22.5004 27.9444 22.5004 28.5833Z" fill="#5D3E81"></path>
        <path d="M41.0554 23.0276H28.0554C27.4443 23.0276 26.9443 22.5276 26.9443 21.9164V8.88867C27.4999 8.94423 28.3332 9.02756 28.3332 9.11089V21.6109H40.8332C40.9443 21.6387 40.9999 22.472 41.0554 23.0276Z" fill="#5D3E81"></path>
        <path d="M41.0277 27.4722C40.9721 27.7499 40.8888 28.3055 40.7777 29.1388H28.3332V40.8055C28.3332 40.8888 27.4999 40.9722 26.9443 41.0277V28.5833C26.9443 27.9722 27.4443 27.4722 28.0554 27.4722H41.0277Z" fill="#5D3E81"></path>
        <path d="M43.639 23.1111C43.5834 22.6389 43.5279 22.1667 43.4445 21.6944C42.0834 13.9722 36.0001 7.88889 28.2779 6.52778C27.8057 6.44444 27.3334 6.38889 26.8612 6.33333C26.2501 6.27777 25.639 6.25 25.0279 6.25C24.3057 6.25 23.6112 6.27778 22.889 6.36111C22.4168 6.41666 21.9445 6.5 21.4723 6.58334C13.889 8.02778 7.91678 14.0833 6.58344 21.6944C6.50011 22.1667 6.44455 22.6389 6.38899 23.1111C6.33344 23.7222 6.30566 24.3333 6.30566 24.9444C6.30566 25.6667 6.33344 26.3611 6.41677 27.0833C6.47233 27.5556 6.55566 28.0278 6.63899 28.5C8.08344 36 13.9723 41.8889 21.4723 43.3333C21.9445 43.4167 22.4168 43.5 22.889 43.5555C23.5834 43.6389 24.3057 43.6667 25.0279 43.6667C25.639 43.6667 26.2501 43.6389 26.8612 43.5833C27.3334 43.5278 27.8057 43.4722 28.2779 43.3889C35.9168 42.0556 41.9446 36.0833 43.389 28.5C43.4723 28.0278 43.5557 27.5556 43.6112 27.0833C43.6945 26.3889 43.7223 25.6667 43.7223 24.9444C43.7223 24.3333 43.6946 23.7222 43.639 23.1111ZM40.7779 28.5C39.389 34.6667 34.4723 39.5278 28.2779 40.7778C27.8057 40.8611 27.3334 40.9444 26.8612 41C26.2501 41.0833 25.639 41.1111 25.0279 41.1111C24.3057 41.1111 23.5834 41.0556 22.889 40.9722C22.4168 40.9167 21.9445 40.8333 21.4723 40.7222C15.389 39.3611 10.6112 34.5833 9.2501 28.5C9.13899 28.0278 9.05566 27.5556 9.0001 27.0833C8.91677 26.3889 8.86121 25.6667 8.86121 24.9444C8.86121 24.3333 8.88899 23.6944 8.97232 23.1111C9.02788 22.6389 9.11122 22.1667 9.19455 21.6944C10.4723 15.4722 15.3057 10.5556 21.4723 9.19445C21.9445 9.08333 22.4168 9 22.889 8.94445C23.5834 8.86111 24.3057 8.80556 25.0279 8.80556C25.639 8.80556 26.2779 8.83333 26.8612 8.91667C27.3334 8.97222 27.8057 9.05555 28.2779 9.13889C34.5834 10.4444 39.5557 15.3889 40.8612 21.7222C40.9446 22.1944 41.0279 22.6667 41.0834 23.1389C41.1668 23.75 41.1945 24.3611 41.1945 24.9722C41.1945 25.6944 41.139 26.4167 41.0557 27.1111C40.9723 27.5556 40.889 28.0278 40.7779 28.5Z" fill="#5D3E81"></path>
      </SvgIcon>
    </LogoWrapper>
  );
};
