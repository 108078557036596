import moment from 'moment';

export const getActualDays = (locale: string, isMobile: boolean) => {
  const actualDays = [];

  for (let counter = 0; counter < 7; counter++) {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const dateObject =  incrementDate(date, counter);
    const momentObject = incrementMoment(counter);
    const formattedDayName = getFormattedDayName(momentObject, locale, isMobile);
    const formattedDate = getFormattedDate(momentObject, locale, isMobile);

    const day = {
      date: dateObject,
      dayName: formattedDayName,
      humanFormat: formattedDate,
    };
    actualDays.push(day);
  }

  return actualDays;
};

const incrementMoment = (days: number) => {
  return moment().add(days, 'days');
};

const getFormattedDate = (date : moment.Moment, locale: string, isMobile: boolean): string => {
  let formatted;

  if (isMobile) {
    formatted = date.locale(locale).format('L').replace(/\.[0-9][0-9][0-9][0-9]|\/[0-9][0-9][0-9][0-9]/, '');
  } else {
    formatted = date.locale(locale).format('D MMMM');
  }

  return formatted;
};

const getFormattedDayName = (date: moment.Moment, locale: string, isMobile: boolean): string => {
  let dayName;

  if (isMobile) {
    dayName = date.locale(locale).format('ddd');
  } else {
    dayName = date.locale(locale).format('dddd');
  }

  return dayName;
};

const incrementDate = (date: Date , days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
};
