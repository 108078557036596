import { SvgIcon } from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import { useLang } from 'locales';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';


const RadioContainer = styled('a')<{ theme: DefaultTheme }>(({theme}) => ({
  display: 'block',
  flex: 'unset',
  position: 'relative',
  paddingLeft: '39px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 48.23,
    display: 'none',
  }
}));

const SvgIconStyled = styled(SvgIcon)<{ theme: DefaultTheme }>(({theme}) => ({
  height: '25px',
  width: '25px',
  [theme.breakpoints.down('md')]: {
    height: 32,
    width: 32,
  },
  fill: theme.palette.primary.main,
  display: 'block',
  position: 'absolute',
  top: '2px',
  left: 0,
}));

const Title = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  font: `normal 24px/29.3px ${theme.fonts.calibri.main}`,
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    font: `normal 17px/21px ${theme.fonts.calibri.main}`,
    fontWeight: 400,
  },
}));

const SubTitle = styled('div')<{ theme: DefaultTheme }>(({theme}) => ({
  font: `normal 15px/18.31px ${theme.fonts.calibri.main}`,
  color: theme.colors.tundora,
  [theme.breakpoints.down('md')]: {
    font: `normal 11px/13px ${theme.fonts.calibri.main}`,
    fontWeight: 400,
  },
}));

export const MariyaButton = () => {
  const theme = useTheme<DefaultTheme>();
  const ctx = useLang();

  return (
    <RadioContainer theme={theme} href='https://www.radiomaria.by/program' rel="noreferrer" target={'_blank'}>
      <SvgIconStyled theme={theme} viewBox='0 0 26 26'>
        <path clipRule="evenodd" d="M9.63073 7.69826H22.5998C23.9253 7.69826 24.9999 8.77279 24.9999 10.0983V22.0985C24.9999 23.424 23.9253 24.4985 22.5998 24.4985H3.39955C2.07404 24.4985 0.999512 23.424 0.999512 22.0985V10.0983C0.999512 8.86343 1.93212 7.84638 3.13142 7.71307L20.9541 0.583984L21.8455 2.81236L9.63073 7.69826ZM3.02456 10.0215V22.475H22.9891V10.0215H3.02456ZM15.6789 16.1829V18.1922H21.0371V16.1829H15.6789ZM15.3999 14.8987V12.4987H21.3999V14.8987H15.3999ZM4.59981 16.0989C4.59981 18.7499 6.74887 20.899 9.39987 20.899C12.0509 20.899 14.1999 18.7499 14.1999 16.0989C14.1999 13.4479 12.0509 11.2989 9.39987 11.2989C6.74887 11.2989 4.59981 13.4479 4.59981 16.0989ZM9.40003 18.8431C10.9157 18.8431 12.1444 17.6144 12.1444 16.0988C12.1444 14.5831 10.9157 13.3544 9.40003 13.3544C7.88436 13.3544 6.65566 14.5831 6.65566 16.0988C6.65566 17.6144 7.88436 18.8431 9.40003 18.8431ZM8.19984 16.0986C8.19984 16.7613 8.73711 17.2986 9.39986 17.2986C10.0626 17.2986 10.5999 16.7613 10.5999 16.0986C10.5999 15.4358 10.0626 14.8986 9.39986 14.8986C8.73711 14.8986 8.19984 15.4358 8.19984 16.0986Z" fillRule="evenodd">
        </path>
      </SvgIconStyled>
      <Title theme={theme}>
        {ctx.radioTitle}
      </Title>
      <SubTitle theme={theme}>
        {ctx.radioSubTitle}
      </SubTitle>
    </RadioContainer>
  );
};
