import {
  Link,
  Stack,
} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import Select from 'components/Select';
import { useStore } from 'effector-react';
import { LANGUAGES } from 'locales';
import { $language } from 'models/staticData';
import { useRouter } from 'next/router';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

const StyledLink = styled(Link)<{ theme: DefaultTheme }>(({theme}) => ({
  textDecoration: 'none',
  textTransform: 'uppercase',
  marginLeft: '18px',
  cursor: 'pointer',
  fontFamily: theme.fonts.calibri.main,
  fontSize: '20px',
  fontStyle: 'normal',
  color: theme.colors.tundora,

  '&:hover, &:focus, &:visited': {
    textDecoration: 'none',
    outline: 'none',
  },

  '&.active': {
    color: theme.palette.primary.main,
    fontWeight: '700'
  }
}));


const StyledSelect = styled(Select)<{ theme: DefaultTheme }>(({ theme }) => ({
  margin: '0 16px 0 0',
  font: `normal 20px ${theme.fonts.calibri.main}`,
  position: 'absolute',
  top: 0,
  right: '0',
  height: '66px',
  zIndex: '9999',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  '& svg': {
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    transition: '.5s',
  },
}));


export const LangSwitcher = ({
  url
}: {url: string}) => {
  const router = useRouter();
  const theme = useTheme<DefaultTheme>();

  const language = useStore($language);

  return(
    <>
      {/* Horizontal Links */}
      <Stack
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'flex',
          }
        }}
        alignItems='center'
        justifyContent={'flex-end'}
        paddingTop={'25px'}
        marginRight={'15px'}
        component={'ul'}
        direction={'row'}
      >
        {LANGUAGES.map((lang : string)=>{
          return (
            <StyledLink
              theme={theme}
              onClick={() => {
                router.push(`/${lang}${url}`);
              }}
              key={lang}
              className={language === lang ? 'active' : ''}
            >
              {lang}
            </StyledLink>
          );
        })}
      </Stack>

      {/* Burger Links */}
      <StyledSelect
        theme={theme}
        value={language}
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none !important',
          }
        }}
        onChange={
          (val: string) => {
            router.push(`/${val}${url}`);
          }
        }
        options={LANGUAGES}
      />
    </>
  );
};
