import { MariyaButton } from 'components/Filter/MariyaButton';
import Link from 'components/Link';
import { SocialNetworks } from 'components/MainHeader/SocialNetworks';
import { useEvent } from 'effector-react';
import { useLang } from 'locales';
import { switchMenu } from 'models/app';
import React from 'react';

const MobileMenu = () => {
  const ctx = useLang();
  const {NEXT_PUBLIC_FORM_URL} = process.env;
  const switchMenuEv = useEvent(switchMenu);

  return (
    <div style={{padding: '0 16px'}}>
      <div style={{
        marginTop: 24,
        marginBottom: 40,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 12,
      }}>
        <Link url={NEXT_PUBLIC_FORM_URL || ''} simple target={'_blank'} rel="noreferer" title={ctx.link.registerVolunteer} />
        <Link onClick={() => switchMenuEv()} url="" title={ctx.link.schedule} />
        <Link onClick={() => switchMenuEv()} url="/about-project" title={ctx.link.about} />
      </div>
      <SocialNetworks />
      <div style={{marginTop: 40}}>
        <MariyaButton />
      </div>
    </div>
  );
};

export default MobileMenu;
