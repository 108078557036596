import { Link as BaseLink, LinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useStore } from 'effector-react';
import { $language } from 'models/staticData';
import { useRouter } from 'next/router';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

interface styledProps extends LinkProps {
  theme?: DefaultTheme,
}


const StyledLink = styled(BaseLink)<styledProps>(({ theme }) => ({
  font: `normal 17px/20.75px ${theme.fonts.calibri.main}`,
  fontWeight: 400,
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: theme.colors.tundora,

  '&.active': {
    color: theme.palette.primary.main,
  }
}));


interface classProps {
  url: string,
  title: string,
  simple?: boolean,
  target?: string,
  rel?: string,
  onClick?: () => void,
}


const Link = ({
  url,
  title,
  simple=false,
  target,
  rel,
  onClick
}: classProps) => {
  const router = useRouter();
  const lang = useStore($language);
  const active = router.pathname == `/[lang]${url}`;

  return (
    <p>
      {(!simple && <StyledLink
        onClick={() => {
          router.push(`/${lang}${url}`);
          onClick && onClick();
        }}
        className={active ? 'active': undefined}
        target={target}
        rel={rel}
      >
        {title}
      </StyledLink>) ||
        (simple && <StyledLink
          href={url}
          className={undefined}
          target={target}
          rel={rel}
        >
          {title}
        </StyledLink>)
      }
    </p>
  );
};

export default Link;
