import {styled,useTheme} from '@mui/material/styles';
import { useLang } from 'locales';
import React from 'react';
import { DefaultTheme } from 'styles/Theme/types';

import InstagramIcon from './icons/instagram_vector.svg';
import VkIcon from './icons/vk_vector.svg';

const Container = styled('div')<{theme: DefaultTheme}>(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: 21.5,
  right: 0,
  [theme.breakpoints.down('md')]: {
    position: 'unset',
    justifyContent: 'unset',
    alignItems: 'center',
  }
}));

const Text = styled('span')<{theme: DefaultTheme}>(({theme}) => ({
  font: `normal 24px/29.3px ${theme.fonts.calibri.main}`,
  color: theme.palette.primary.main,
  fontWeight: 400,
  marginRight: 20,
  [theme.breakpoints.down('md')]: {
    font: `normal 17px/21px ${theme.fonts.calibri.main}`,
    marginRight: 32,
  },
}));

const IconWrapper = styled('div')<{theme: DefaultTheme}>(({theme}) => ({
  display: 'flex',
  width: 67,
  justifyContent: 'space-between',
  '& path': {
    fill: theme.palette.primary.main
  },
  [theme.breakpoints.down('md')]: {
    '& svg, & a': {
      width: 28,
      height: 28,
    },
    width: 80,
  }
}));

export const SocialNetworks = () => {
  const theme = useTheme<DefaultTheme>();
  const ctx = useLang();


  return (
    <Container theme={theme}>
      <Text theme={theme}>{ctx.header.socialNetwork}</Text>
      <IconWrapper theme={theme}>
        <a target="_blank" className='instagram' href="https://www.instagram.com/imsha.by/" rel="noreferrer">
          <InstagramIcon />
        </a>
        <a target="_blank" className='vk' href="https://vk.com/byimsha" rel="noreferrer">
          <VkIcon />
        </a>
      </IconWrapper>
    </Container>
  );
};
